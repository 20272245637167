/*
  LaCorelli custom calendar script based on https://github.com/jackducasse/caleandar
*/
const Calendar = function(model, options, date){
  // Default Values
  this.options = {
    context: null,
    eventClick: '',
    displayNextPrevDays: false
  };

  // Overwriting default values
  for (let key in options) {
    this.options[key] = typeof options[key] == 'string' ? options[key].toLowerCase() : options[key];
  }

  if (model) {
    this.Model = model
  } else {
    this.Model = {};
  }

  this.Today = new Date();
  this.Selected = this.Today;
  this.TodayMonth = this.Today.getMonth();
  this.TodayYear = this.Today.getFullYear();
  if (date) {
    this.Selected = date
  }
  this.Selected.Month = this.Selected.getMonth();
  this.Selected.Year = this.Selected.getFullYear();

  this.Selected.Days = new Date(this.Selected.Year, (this.Selected.Month + 1), 0).getDate();
  this.Selected.FirstDay = new Date(this.Selected.Year, (this.Selected.Month), 0).getDay();
  this.Selected.LastDay = new Date(this.Selected.Year, (this.Selected.Month + 1), 0).getDay();

  this.Prev = new Date(this.Selected.Year, (this.Selected.Month - 1), 1);
  if (this.Selected.Month===0) {
    this.Prev = new Date(this.Selected.Year-1, 11, 1);
  }
  this.Prev.Days = new Date(this.Prev.getFullYear(), (this.Prev.getMonth() + 1), 0).getDate();
};

function createCalendar(calendar, element, adjuster){

  if (typeof adjuster !== 'undefined') {
    let newDate = new Date(calendar.Selected.Year, calendar.Selected.Month + adjuster, 1);
    calendar = new Calendar(calendar.Model, calendar.options, newDate);
    element.innerHTML = '';
  } else {
    // ???
    for (let key in calendar.options) {
      typeof calendar.options[key] != 'function' && typeof calendar.options[key] != 'object' && calendar.options[key]?element.className += " " + key + "-" + calendar.options[key]:0;
    }
  }
  let months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
  let labelsList = ["Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do"];

  let mainSection = document.createElement('div');
  mainSection.className += "cld-main";

  function AddNavigation(){
    let datetime = document.createElement('div');
    datetime.className += "cld-datetime";

    // nav -1
    let rwd = document.createElement('div');
    rwd.className += " cld-rwd cld-nav";
    rwd.addEventListener('click', () => createCalendar(calendar, element, -1));
    rwd.innerHTML = '<svg height="15" width="15" viewBox="0 0 75 100" fill="rgba(0,0,0,0.5)"><polyline points="0,50 75,0 75,100"></polyline></svg>';
    datetime.appendChild(rwd);

    // today
    let today = document.createElement('div');
    today.className += ' today';
    today.innerHTML = months[calendar.Selected.Month] + " " + calendar.Selected.Year;
    datetime.appendChild(today);

    // nav +1
    let fwd = document.createElement('div');
    fwd.className += " cld-fwd cld-nav";
    fwd.addEventListener('click', () => createCalendar(calendar, element, 1));
    fwd.innerHTML = '<svg height="15" width="15" viewBox="0 0 75 100" fill="rgba(0,0,0,0.5)"><polyline points="0,0 75,50 0,100"></polyline></svg>';
    datetime.appendChild(fwd);

    mainSection.appendChild(datetime);
  }

  function AddLabels(){
    let labels = document.createElement('ul');
    labels.className = 'cld-labels';
    for (let i = 0; i < labelsList.length; i++) {
      const label = document.createElement('li');
      label.className = "cld-label";
      label.innerHTML = labelsList[i];
      labels.appendChild(label);
    }
    mainSection.appendChild(labels);
  }

  function AddDays(){
    // Create Number Element
    function DayNumber(n){
      const number = document.createElement('p');
      number.className = "cld-number";
      number.innerHTML = n;
      return number;
    }

    const days = document.createElement('ul');
    days.className = "cld-days";

    // Previous Month's Days
    for (let i = 0; i < (calendar.Selected.FirstDay); i++){
      let day = document.createElement('li');
      day.className += "cld-day prev-month";

      let number;
      if (calendar.options.displayNextPrevDays) {
        number = DayNumber((calendar.Prev.Days - calendar.Selected.FirstDay) + (i+1));
      } else {
        number = DayNumber(null);
      }

      day.appendChild(number);

      days.appendChild(day);
    }

    // Current Month's Days
    for (let i = 0; i < calendar.Selected.Days; i++) {
      let day = document.createElement('li');
      day.className = "cld-day curr-month";
      let number = DayNumber(i + 1);

      // Check Date against Event Dates
      for (let n = 0; n < calendar.Model.length; n++) {
        let evDate = calendar.Model[n].date_moment.toDate();
        let toDate = new Date(calendar.Selected.Year, calendar.Selected.Month, (i + 1));
        if (evDate.getFullYear() === toDate.getFullYear() && evDate.getMonth() === toDate.getMonth() && evDate.getDate() === toDate.getDate()) {
          number.className += " eventday";
          if (calendar.options.eventClick && !day.className.includes("clickable")) {
            day.className += " clickable";
            day.addEventListener('click', calendar.options.eventClick.bind(calendar.options.context, /*calendar.Model[n]*/ toDate));
          }
        }
      }

      day.appendChild(number);

      // If Today..
      if ((i + 1) === calendar.Today.getDate() && calendar.Selected.Month === calendar.TodayMonth && calendar.Selected.Year === calendar.TodayYear) {
        day.className += " today";
      }
      days.appendChild(day);
    }

    // Next Month's Days
    if (calendar.Selected.LastDay !== 0) {
      for (let i = 0; i < (7 - calendar.Selected.LastDay); i++) {
        let day = document.createElement('li');
        day.className += "cld-day next-month";

        let number;
        if (calendar.options.displayNextPrevDays) {
          number = DayNumber(i+1);
        } else {
          number = DayNumber(null);
        }
        day.appendChild(number);

        days.appendChild(day);
      }
    }

    mainSection.appendChild(days);
  }

  element.appendChild(mainSection);

  AddNavigation();
  AddLabels();
  AddDays();
}

function calendar(el, data, settings){
  let obj = new Calendar(data, settings, null);
  createCalendar(obj, el);
}
